import { useEffect, useState } from "react";

export type CountdownOptions = {
    onCountdownComplete: () => void;
    countdownTime: number;
}

export function useCountdown({ onCountdownComplete, countdownTime }: CountdownOptions) {
    const [timeRemaining, setTimeRemaining] = useState<number>(countdownTime);

    useEffect(() => {        
        const timerInterval = setInterval(() => {
            setTimeRemaining(prevTime => { 
                if (prevTime === 0) {
                    clearInterval(timerInterval);
                    return 0;
                }

                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timerInterval);
    }, []);

    
    if (timeRemaining ===  0) {
        onCountdownComplete();
    }

    return timeRemaining;
}