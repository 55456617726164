import config from "#app/env";
import { createActivityTimer, killTimer, resetTimer, startTimer } from "#app/lib/activityTimer";
import { TimeSpan } from "#app/lib/TimeSpan";
import { useEffect, useState } from "react";

export const activityTimerName = "Inactivity"; 
function throttleResetTimer() {
    let inThrottle = false;
    if (!inThrottle) {
        resetTimer(activityTimerName, {})
        inThrottle = true;

        setTimeout(function () {
            inThrottle = false;
        }, 500);
    }
}

type InactivityTimerStatus = "Init" | "Running" | "Stopped" | "Failed"
export function useInactivityTimer() {
    const [status, setStatus] = useState<InactivityTimerStatus>("Init");
    useEffect(() => {
        createActivityTimer({
            name: activityTimerName,
            getDelay: (_: any) => TimeSpan.fromSeconds(config.autoLogOffIdleTime),
            onTrigger: async (_: any) => {
                setStatus("Stopped");
                return false;
            },
            failed: async (_: any) => {
                setStatus("Failed");
            },
            stopped: () => {
                setStatus("Stopped");
            },
        });
        startTimer(activityTimerName, {});
        setStatus("Running");
        document.addEventListener("keydown", throttleResetTimer, false);
        document.addEventListener("keyup", throttleResetTimer, false);
        document.addEventListener("click", throttleResetTimer, false);

        return () => {
            document.removeEventListener("keydown", throttleResetTimer);
            document.removeEventListener("keyup", throttleResetTimer);
            document.removeEventListener("click", throttleResetTimer);
            killTimer(activityTimerName);
        };
    }, []);

    return {
        status
    };
}