import config from "#app/env";
import { resetTimer } from "#app/lib/activityTimer";
import { useRouter } from "next/navigation";
import { useCountdown } from "./Countdown";
import { activityTimerName } from "./InactivityTimer";

export function AutoLogoffBanner() {
    const router = useRouter();
    const timeRemaining = useCountdown({
        onCountdownComplete: () => router.push("/sign-out"),
        countdownTime: config.autoLogOffCountdownTime
    });

    return (
        <div>
            Your Baird Online session will expire in
            <span>
                {timeRemaining}
            </span>{" "}
            seconds.
            <button onClick={() => resetTimer(activityTimerName, {})}>
                Click here to continue using Baird Online.
            </button>
        </div>
    )
}